const api = "https://api.q-highschool.nl";

export async function fetchGroups(handleError) {
	const { enrollmentBlock, enrollmentSchoolYear } = await fetch(
		api + "/api/sync/constants"
	).then((res) => res.json());

	const isFuture = ({ period, schoolYear }) => {
		if (!schoolYear || !period) return false;
		if (schoolYear === enrollmentSchoolYear) {
			return Number(period) >= Number(enrollmentBlock);
		} else {
			return (
				Number(schoolYear.slice(0, 4)) >
				Number(enrollmentSchoolYear.slice(0, 4))
			);
		}
	};

	return fetch(api + "/api/groups")
		.then((res) => res.json())
		.then((groups) => {
			let groupsPerSubject = {};
			Object.values(groups)
				.filter(isFuture)
				.filter(({ enrollable }) => enrollable)
				.forEach((group) => {
					Object.values(group.courses).forEach((course) => {
						const subjectName = course.subject.name;
						if (!groupsPerSubject[subjectName]) {
							groupsPerSubject[subjectName] = {};
						}
						groupsPerSubject[subjectName][group.id] = group;
					});
				});
			return groupsPerSubject;
		})
		.catch(handleError);
}

export async function fetchSubjectInformation(handleError) {
	return fetch(api + "/api/subjects")
		.then((res) => res.json())
		.then((subjects) => {
			let informationPerSubject = {};
			Object.values(subjects).forEach((s) => {
				informationPerSubject[s.name] = s.description;
			});
			return informationPerSubject;
		})
		.catch(handleError);
}
