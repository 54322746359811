import React, { Component } from "react";
import GroupInfo from "./components/GroupInfo";
import SubjectRow from "./components/SubjectRow";
import SubjectInfo from "./components/SubjectInfo";
import { fetchGroups, fetchSubjectInformation } from "./fetchData";
import LoadIcon from "./components/LoadIcon";
import "./App.css";

function getType(subject) {
	if (subject === "Avonturen") {
		return 0;
	} else if (subject === "Trajecten") {
		return 1;
	} else {
		return 2;
	}
}

function sortByType(a, b) {
	if (getType(a) !== getType(b)) {
		return getType(a) - getType(b);
	} else if (a !== b) {
		return a < b ? -1 : 1;
	} else {
		return 0;
	}
}

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			popOut: null,
			subjectInfo: null,
			groups: null,
			subjectDescriptions: null,
			serverError: false,
			clientError: null,
			clientErrorInfo: null,
		};
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components below and re-render with error message
		this.setState({
			error: error,
			errorInfo: errorInfo,
		});
	}

	componentDidMount = () => {
		fetchGroups(this.handleError).then((groups) =>
			this.setState({ groups: groups, serverError: false })
		);

		fetchSubjectInformation(this.handleError).then((subjectDescriptions) =>
			this.setState({
				subjectDescriptions: subjectDescriptions,
				serverError: false,
			})
		);
	};

	handleError = (e) => {
		console.error(e);
		this.setState({
			serverError: true,
		});
	};

	onClick = (groupId, group) => {
		let popOut = this.state.popOut;
		if (popOut && popOut.group === group && popOut.groupId === groupId) {
			popOut = null;
		} else {
			popOut = {
				group: group,
				groupId: groupId,
			};
		}
		this.setState({
			popOut: popOut,
		});
	};

	showSubjectInfo = (subject) => {
		if (subject === this.state.subjectInfo) {
			this.removeSubjectInfo();
			return;
		}
		this.setState({
			subjectInfo: subject,
		});
	};

	removeSubjectInfo = () => {
		this.setState({
			subjectInfo: null,
		});
	};

	render() {
		let { popOut, groups, subjectInfo, subjectDescriptions, serverError } =
			this.state;
		if (serverError) {
			return (
				<h1 className="error">
					Er is een fout opgetreden met het verbinden met de server.
				</h1>
			);
		}
		if (this.state.errorInfo) {
			// Error path
			return (
				<>
					<h1 className="error">
						Er is een fout opgetreden bij het laden van de pagina. <br />
						Vermeld bij het rapporteren van deze fout de volgende diagnostische
						informatie:
					</h1>
					<div className="codeblock">
						<p>{this.state.error ? this.state.error.toString() : null}</p>
						<pre className="stack">{this.state.errorInfo.componentStack}</pre>
					</div>
				</>
			);
		}
		if (!groups) {
			return <LoadIcon />;
		}
		const subjects = Object.keys(groups)
			.sort()
			.filter((subjectName) => !subjectName.startsWith("@"));
		let nextSubject, previousSubject;
		if (subjectInfo) {
			let subjectInfoId = subjects.indexOf(subjectInfo);
			if (subjectInfoId < subjects.length - 1)
				nextSubject = () => this.showSubjectInfo(subjects[subjectInfoId + 1]);
			if (subjectInfoId > 0)
				previousSubject = () =>
					this.showSubjectInfo(subjects[subjectInfoId - 1]);
		}
		return (
			<div className="App">
				{subjectInfo && (
					<SubjectInfo
						nextSubject={nextSubject}
						previousSubject={previousSubject}
						subject={subjectInfo}
						description={subjectDescriptions[subjectInfo]}
						onClose={() => this.showSubjectInfo(null)}
					/>
				)}
				{subjects.sort(sortByType).map((subject, key) => (
					<React.Fragment key={key}>
						<SubjectRow
							title={subject}
							key={key}
							groups={groups[subject]}
							onClick={(groupId) => this.onClick(groupId, subject)}
							selectedGroup={popOut && popOut.groupId}
							showSubjectInfo={() => this.showSubjectInfo(subject)}
						/>
						{popOut && popOut.group === subject && (
							<GroupInfo
								group={groups[popOut.group][popOut.groupId]}
								key={Math.random()}
								subject={subject}
								onClose={(_) => this.onClick(popOut.groupId, popOut.group)}
							/>
						)}
					</React.Fragment>
				))}
				<div style={{ height: "300px" }} />
			</div>
		);
	}
}

export default App;
